<template>
  <div :class="$style.orders">
    <div :class="$style.wrapper"></div>
    <el-descriptions title="Заказ" border direction="vertical" :column="5">
      <el-descriptions-item label="Название">
        {{ order.name }}
      </el-descriptions-item>
      <el-descriptions-item label="Тип">
        {{ order.deliveryType === 'SELFDELIVERY' ? 'Самовывоз' : 'Доставка' }}
      </el-descriptions-item>
      <el-descriptions-item label="Оплата">
        {{ order.paymentType.name }}
      </el-descriptions-item>
      <el-descriptions-item label="Дата доставки">
        {{ formatDate(order.deliveryAt) }}
      </el-descriptions-item>
      <el-descriptions-item label="Экспресс доставка">
        <input
          type="checkbox"
          :checked="order.isExpressDelivery"
          style="pointer-events: none"
        />
      </el-descriptions-item>
      <el-descriptions-item v-if="order.adPromo" label="Рекламный промо">
        <router-link
          :to="'/addwine/promotions/promos/ad-promos/' + order.adPromo.id"
        >
          {{ order.adPromo.code }}
        </router-link>
        <span v-if="order.adPromo.type === $options.PROMOCODE_TYPE.PERCENTAGE"
          >(Скидка: {{ order.adPromo.value }}%)</span
        >
      </el-descriptions-item>
      <el-descriptions-item v-if="order.giftPromo" label="Подарочный промо">
        <router-link
          :to="'/addwine/promotions/promos/gift-promos/' + order.giftPromo.id"
        >
          {{ order.giftPromo.code }}
        </router-link>
        <span v-if="order.giftPromo.type === $options.PROMOCODE_TYPE.PERCENTAGE"
          >(Скидка: {{ order.giftPromo.value }}%)</span
        >
      </el-descriptions-item>
      <el-descriptions-item label="Цена">
        {{ order.price }}
      </el-descriptions-item>
      <el-descriptions-item label="Скидка">
        {{ order.discount }}
      </el-descriptions-item>
      <el-descriptions-item label="Цена доставки">
        {{ order.deliveryPrice }}
      </el-descriptions-item>
      <el-descriptions-item label="Итого">
        {{
          order.price - order.discount + order.deliveryPrice > 0
            ? order.price - order.discount + order.deliveryPrice
            : 0
        }}
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <el-descriptions
      v-if="order.bonusTransactionWriteOff"
      title="Списанные бонусы"
      border
      direction="vertical"
      :column="4"
    >
      <el-descriptions-item label="Количество">
        {{ order.bonusTransactionWriteOff.value }}
      </el-descriptions-item>
      <el-descriptions-item label="Коэффициент списания">
        {{ order.bonusTransactionWriteOff.coefficient }}
      </el-descriptions-item>
      <el-descriptions-item label="В рублях">
        {{
          order.bonusTransactionWriteOff.value *
          order.bonusTransactionWriteOff.coefficient
        }}
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <el-descriptions
      v-if="order.bonusTransactionAccrual"
      title="Начисленные бонусы"
      border
      direction="vertical"
      :column="4"
    >
      <el-descriptions-item label="Количество">
        {{ order.bonusTransactionAccrual.value }}
      </el-descriptions-item>
      <el-descriptions-item label="Коэффициент начисления">
        {{ order.bonusTransactionAccrual.coefficient }}
      </el-descriptions-item>
      <el-descriptions-item label="В рублях">
        {{
          order.bonusTransactionAccrual.value /
          order.bonusTransactionAccrual.coefficient
        }}
      </el-descriptions-item>
      <el-descriptions-item label="Дата сгорания">
        {{ formatDate(order.bonusTransactionAccrual.expiresAt) }}
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <el-descriptions title="Получатель" border direction="vertical" :column="4">
      <el-descriptions-item label="Имя">
        {{ order.client.firstName + ' ' + order.client.lastName }}
      </el-descriptions-item>
      <el-descriptions-item label="Телефон">
        {{ order.client.phone }}
      </el-descriptions-item>
      <el-descriptions-item label="Email">
        {{ order.client.email }}
      </el-descriptions-item>
      <el-descriptions-item label="Комментарий">
        {{ order.comment }}
      </el-descriptions-item>
      <el-descriptions-item v-if="order.deliveryAddress" label="Город">
        {{ order.deliveryAddress.city }}
      </el-descriptions-item>
      <el-descriptions-item v-if="order.deliveryAddress" label="Улица ">
        {{ order.deliveryAddress.street }}
      </el-descriptions-item>
      <el-descriptions-item v-if="order.deliveryAddress" label="Доп инфо">
        <template v-if="order.deliveryAddress.additionalInfo">
          Частный дом:
          {{
            order.deliveryAddress.additionalInfo.isDetachedHouse ? 'Да' : 'Нет'
          }},
          <span v-if="!order.deliveryAddress.additionalInfo.isDetachedHouse">
            Квартира: {{ order.deliveryAddress.additionalInfo.flat }}, Подъезд:
            {{ order.deliveryAddress.additionalInfo.entrance }}, Этаж:
            {{ order.deliveryAddress.additionalInfo.floor }}, Домофон:
            {{ order.deliveryAddress.additionalInfo.intercom }}
          </span>
        </template>
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <el-descriptions title="Товары" />
    <div v-for="pr in order.products" :key="pr.id">
      <div :class="$style.product">
        <img :src="$configData.s3_link + pr.imagePath || ''" alt="image" />
        <div>{{ pr.vendorCode }}</div>
        <div>{{ pr.name }}</div>
        <div>
          {{ pr.price + '*' + pr.quantity + '=' + pr.price * pr.quantity }}
        </div>
      </div>
      <div v-if="pr.presentId" :class="$style.product">
        <img
          :src="$configData.s3_link + pr.present.imagePath || ''"
          alt="image"
        />
        <div>{{ pr.present.vendorCode }}</div>
        <div>{{ pr.present.name }}</div>
        <div>Подарок</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/index.js'
import delivery from '@/delivery'
import { PROMOCODE_TYPE } from '@/constants/promocodeTypes'
export default {
  PROMOCODE_TYPE,
  data() {
    return {
      order: {
        id: '',
        name: '',
        paymentType: {},
        client: {},
        deliveryAddress: {},
        products: []
      },
      total: 0,
      page: 1,
      limit: 20
    }
  },
  created() {
    this.getOrder()
  },
  methods: {
    async getOrder() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.PaymentServiceCore.GeneralOrdersActions.getById(
          this.$route.params.id
        )
      loading.close()
      if (error) return
      this.order = value
      this.order.deliveryAddress.additionalInfo = JSON.parse(
        this.order.deliveryAddress.additionalInfo
      )
    },
    formatDate(date) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" module>
.orders {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .product {
    display: grid;
    grid-template-columns: 1fr 1fr 6fr 1fr;
    margin-bottom: 0.5rem;
    img {
      width: 5rem;
      height: 5rem;
    }
  }
}
</style>
